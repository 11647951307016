/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { GatsbyBrowser } from 'gatsby';
import 'normalize.css';
import '../styles/index.css';
import '../styles/bpClasses.css';

// FIX: NH-344, on initial load the browser does not always scroll to the anchor by itself
// so we trigger a scroll by hand
const scrollTo = (id: string) => () => {
  const el = document.querySelector(id);
  if (el) {
    return window.scrollTo(0, (el as any).offsetTop || el.clientTop);
  }
  return false;
};

export const gatsbyBrowser: GatsbyBrowser = {
  onRouteUpdate: ({ location: { hash } }) => {
    if (hash) {
      // trigger scroll after a short timeout to make sure hash links / deep links work
      window.setTimeout(scrollTo(hash), 10);
    }
  },
};
